import Vue from 'vue';
import { blockComparisonModule } from './modules/block-comparison-module';
import { toggleAccordionFaq } from './modules/block-faq';
import './modules/calculate-price';
import { toggleVideoPopup } from './modules/block-horizontal-sticky-scrolling-section';
import { showPopupBlockImage } from './modules/block-image';
import { blockLeftAndRightContent } from './modules/block-left-and-right-content';
import { blockPricingOption } from './modules/block-pricing-option';
import { blockScreenshotCarousel } from './modules/block-screenshot-carousel';
import { blockScrollingLogoCarousel } from './modules/block-scrolling-logo-carousel';
import { blockStatisticComponent } from './modules/block-statistic-component';
import { blockSummaryHero } from './modules/block-summary-hero';
import { blockTabbedReveal } from './modules/block-tabbed-reveal';
import { blockTestimonial } from './modules/block-testimonial';
import { blockTestimonialCarousel } from './modules/block-testimonial-carousel';
import './modules/block-image-profile-carousel';
import { createFooterAccordion } from './modules/footer-main';
import { checkHeaderWordpress, heeaderMenubarRight, } from './modules/header-main';
import { setupBlogPagination, showFilterDropdown, toggleDropdownBlog, } from './modules/template-blog';
import { showFormBookNow } from './modules/template-event';
import './modules/pricing';
import './modules/features';
import './modules/customer-story';
import './modules/post';
import { templateSolutionPage } from './modules/template-solution-page';
import './modules/cta-footer';
import './modules/modal';
import './modules/block-contact-form';
import './modules/modal-language';
import './modules/block-video';
import './modules/multimedia';
import './modules/blogs';
import './parts';
import './helpers';
new Vue({
    el: '#app',
});
// Header
heeaderMenubarRight();
checkHeaderWordpress();
createFooterAccordion();
// block-left-and-right-content
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-left-and-right-content')) {
        blockLeftAndRightContent();
    }
});
// block-screenshot-carousel
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-screenshot-carousel')) {
        blockScreenshotCarousel();
    }
});
// block-tabbed-reveal
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-tabbed-reveal')) {
        blockTabbedReveal();
    }
});
// block-testimonial-carousel
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-testimonial-carousel')) {
        blockTestimonialCarousel();
    }
});
// block-statistic-component
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-statistic-component')) {
        blockStatisticComponent();
    }
});
// block-scrolling-logo-carousel
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-scrolling-logo-carousel')) {
        blockScrollingLogoCarousel();
    }
});
// block-testimonial
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-testimonial')) {
        blockTestimonial();
    }
});
// block-pricing-option
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-pricing-option')) {
        blockPricingOption();
    }
});
// block-summary-hero
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-summary-hero')) {
        blockSummaryHero();
    }
});
// block-comparison-module
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-comparison-module')) {
        blockComparisonModule();
    }
});
toggleAccordionFaq();
showFormBookNow();
// template-solution-page
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('template-solution-page')) {
        templateSolutionPage();
    }
});
showFilterDropdown();
toggleDropdownBlog();
setupBlogPagination();
// block-horizontal-sticky-scrolling-section
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-horizontal-sticky-scrolling-section')) {
        toggleVideoPopup();
    }
});
// block-horizontal-sticky-scrolling-section
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('block-image')) {
        showPopupBlockImage();
    }
});
