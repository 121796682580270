"use strict";
document.addEventListener("DOMContentLoaded", function () {
    var currentUrl = window.location.pathname;
    var ctaFooter = document.getElementById("cta-footer");
    if (ctaFooter && !ctaFooter.classList.contains('offset-section')) {
        console.log("Margin adjustment excluded for ".concat(currentUrl));
        // Apply a Tailwind background class if URL is in the exclude list
        if (ctaFooter) {
            ctaFooter.classList.add("bg-[#f8f7f5]");
        }
        return; // Exit to avoid applying the negative margin
    }
    if (ctaFooter) {
        // Get the height of the container element
        var height = ctaFooter.offsetHeight;
        ctaFooter.classList.remove("bg-[#f8f7f5]");
        // Set the margin-top to half of the height
        ctaFooter.style.marginTop = "-".concat(height / 2, "px");
        return;
    }
});
