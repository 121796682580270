export var blockSummaryHero = function () {
    // Get the necessary elements by ID
    var summaryHeroSection = document.getElementById("block-summary-hero");
    var blockSection = document.getElementById("block-section");
    var innerBlocksContainer = document.getElementById("inner-blocks-container");
    var blockTabbedReveal = document.getElementById("block-tabbed-reveal");
    var blockSectionContainer = document.getElementById("block-section-container");
    // Check if blockSection contains summaryHeroSection, then add classes if inner block has children
    if (blockSection && summaryHeroSection && blockSection.contains(summaryHeroSection)) {
        var innerBlock = summaryHeroSection.querySelector(".inner-block");
        if (innerBlock && innerBlock.children.length > 0) {
            innerBlock.classList.add("pt-[40px]", "-mb-[28%]");
            blockSection.classList.add("mb-[23.8%]");
        }
    }
    // Check if blockTabbedReveal is within innerBlocksContainer and adjust width class
    if (innerBlocksContainer && blockTabbedReveal && innerBlocksContainer.contains(blockTabbedReveal)) {
        innerBlocksContainer.classList.remove("md:w-[70%]");
    }
    // If blockTabbedReveal is found in innerBlocksContainer, set margin-bottom for blockSectionContainer
    if (blockSectionContainer && innerBlocksContainer && blockTabbedReveal) {
        var innerBlocksHeight = innerBlocksContainer.offsetHeight;
        blockSectionContainer.style.marginBottom = "".concat(innerBlocksHeight * 0.6, "px");
    }
};
