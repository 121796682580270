import Vue, { defineComponent } from 'vue';
var PER_PAGE = 10;
var Template = "\n<template>\n  <nav class=\"pagination\">\n    <ul class=\"page-numbers\">\n      <li>\n        <button\n          class=\"page-numbers prev\"\n          :disabled=\"currentPage === 1\"\n          @click=\"handlePrev\">\n          <svg width=\"8\" height=\"12\" viewBox=\"0 0 8 12\" fill=\"none\">\n            <path d=\"M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z\" fill=\"#65BB38\" />\n          </svg>\n        </button>\n      </li>\n\n      <template v-for=\"page in pages\">\n        <li :key=\"page\">\n          <span\n            v-if=\"page === -1\"\n            class=\"page-numbers\">...</span>\n          <button\n            v-else\n            class=\"page-numbers\"\n            :class=\"{ current: page === currentPage }\"\n            @click=\"handlePageClick(page)\">\n            {{ page }}\n          </button>\n        </li>\n      </template>\n\n      <li>\n        <button\n          class=\"page-numbers next\"\n          :disabled=\"currentPage === totalPages\"\n          @click=\"handleNext\">\n          <svg width=\"8\" height=\"12\" viewBox=\"0 0 8 12\" fill=\"none\">\n            <path d=\"M0.59 10.59L5.17 6L0.59 1.41L2 0L8 6L2 12L0.59 10.59Z\" fill=\"#65BB38\" />\n          </svg>\n        </button>\n      </li>\n    </ul>\n  </nav>\n</template>\n";
Vue.component('pagination', defineComponent({
    template: Template,
    props: {
        total: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            required: true,
            default: PER_PAGE,
        },
    },
    computed: {
        totalPages: function () {
            return Math.ceil(this.total / this.perPage);
        },
        pages: function () {
            var pages = [];
            if (this.totalPages <= 5) {
                // If total pages is 5 or less, show all pages
                for (var i = 1; i <= this.totalPages; i++) {
                    pages.push(i);
                }
            }
            else {
                // Always show first page
                pages.push(1);
                if (this.currentPage > 3) {
                    pages.push(-1); // Represents "..."
                }
                // Show current page and surrounding pages
                for (var i = Math.max(2, this.currentPage - 1); i <= Math.min(this.totalPages - 1, this.currentPage + 1); i++) {
                    pages.push(i);
                }
                if (this.currentPage < this.totalPages - 2) {
                    pages.push(-1); // Represents "..."
                }
                // Always show last page
                if (this.totalPages > 1) {
                    pages.push(this.totalPages);
                }
            }
            return pages;
        },
    },
    methods: {
        handlePageClick: function (page) {
            if (page !== this.currentPage) {
                this.$emit('page-change', page);
            }
        },
        handlePrev: function () {
            if (this.currentPage > 1) {
                this.$emit('page-change', this.currentPage - 1);
            }
        },
        handleNext: function () {
            if (this.currentPage < this.totalPages) {
                this.$emit('page-change', this.currentPage + 1);
            }
        },
    },
}));
