export var toggleAccordionFaq = function () {
    var accordionItems = document.querySelectorAll('.accordion-item-faq');
    accordionItems.forEach(function (item) {
        var header = item.querySelector('.accordion-header');
        var content = item.querySelector('.accordion-content-faq');
        var plusIcon = item.querySelector('.plus-icon');
        var xIcon = item.querySelector('.x-icon');
        header === null || header === void 0 ? void 0 : header.addEventListener('click', function () {
            // Collapse other accordion items
            accordionItems.forEach(function (otherItem) {
                var otherContent = otherItem.querySelector('.accordion-content-faq');
                var otherPlusIcon = otherItem.querySelector('.plus-icon');
                var otherXIcon = otherItem.querySelector('.x-icon');
                // Only collapse other items, not the one being clicked
                if (otherItem !== item) {
                    otherContent === null || otherContent === void 0 ? void 0 : otherContent.classList.add('hidden');
                    otherPlusIcon === null || otherPlusIcon === void 0 ? void 0 : otherPlusIcon.classList.remove('hidden');
                    otherXIcon === null || otherXIcon === void 0 ? void 0 : otherXIcon.classList.add('hidden');
                    otherItem.classList.remove('active');
                }
            });
            // Toggle the clicked accordion item's content and icon
            content === null || content === void 0 ? void 0 : content.classList.toggle('hidden');
            plusIcon === null || plusIcon === void 0 ? void 0 : plusIcon.classList.toggle('hidden');
            xIcon === null || xIcon === void 0 ? void 0 : xIcon.classList.toggle('hidden');
            // Toggle background and border for active item
            if (content === null || content === void 0 ? void 0 : content.classList.contains('hidden')) {
                item.classList.remove('active');
            }
            else {
                item.classList.add('active');
            }
        });
    });
};
