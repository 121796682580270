export var blockTabbedReveal = function () {
    var tabItems = document.querySelectorAll(".tab-livestock-item");
    var tabContents = document.querySelectorAll(".tab-body-content");
    var tabBodyContainer = document.querySelector(".tab-body");
    // Helper function to remove active classes from all tabs and contents
    function removeActiveClasses() {
        tabItems.forEach(function (tab) { return tab.classList.remove("active"); });
        tabContents.forEach(function (content) {
            content.classList.add("hidden");
            content.classList.remove("slide-in");
        });
    }
    // Helper function to activate the clicked tab and its corresponding content
    function activateTab(tabId) {
        var targetTab = document.querySelector(".tab-livestock-item#".concat(tabId));
        var targetContent = document.querySelector(".tab-body-content#".concat(tabId));
        if (targetTab && targetContent) {
            targetTab.classList.add("active");
            targetContent.classList.remove("hidden");
            targetContent.classList.add("slide-in");
            // Check if the clicked tab is the first tab and adjust the tab-body container's border radius
            if (tabBodyContainer) {
                if (targetTab === tabItems[0]) {
                    tabBodyContainer.classList.remove("rounded-[20px]");
                    tabBodyContainer.classList.add("rounded-r-[20px]", "rounded-bl-[20px]");
                }
                else {
                    tabBodyContainer.classList.remove("rounded-r-[20px]", "rounded-bl-[20px]");
                    tabBodyContainer.classList.add("rounded-[20px]");
                }
            }
        }
    }
    // Initialize by hiding all contents first and then showing the first one
    removeActiveClasses();
    if (tabItems.length > 0) {
        var firstTabId = tabItems[0].id;
        activateTab(firstTabId);
    }
    // Attach click event listeners to each tab item
    tabItems.forEach(function (tab) {
        tab.addEventListener("click", function () {
            var tabId = tab.id;
            removeActiveClasses();
            activateTab(tabId);
        });
    });
};
