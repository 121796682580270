var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue, { defineComponent } from 'vue';
import PostGrid, { createPostGridTemplate } from '@/modules/post/post-grid';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
export default Vue.component('customer-story-grid', 
// @ts-ignore
defineComponent({
    extends: PostGrid,
    template: createPostGridTemplate({
        attributes: {
            ':featured-posts': 'featuredPosts',
        },
    }),
    computed: {
        mappedPosts: function () {
            var _this = this;
            return this.filteredPosts.map(function (post) { return (__assign(__assign({}, post), { region: _this.findPostTermName(post, 'region') })); });
        },
        featuredPosts: function () {
            return this.mappedPosts.filter(function (post) { return post.feature_content; }).slice(0, 10);
        },
        nonFeaturedPosts: function () {
            return this.mappedPosts.filter(function (post) { return !post.feature_content; });
        },
        posts: function () {
            var startIndex = (this.currentPage - 1) * this.perPage;
            var endIndex = startIndex + this.perPage;
            return this.nonFeaturedPosts.slice(startIndex, endIndex);
        },
        totalData: function () {
            return this.nonFeaturedPosts.length;
        },
    },
    watch: {
        terms: function () {
            this.delayedBindSwiper();
        },
        currentPage: function () {
            this.delayedBindSwiper();
        }
    },
    methods: {
        // ================================ ATTENTION ================================
        // TODO: refactor to proper code and make it reusable
        // ================================ ATTENTION ================================
        delayedBindSwiper: function () {
            var _this = this;
            setTimeout(function () {
                _this.bindSwiper();
            }, 100);
        },
        bindSwiper: function () {
            var hasMultipleSlides = this.featuredPosts.length > 2;
            var autoplay = hasMultipleSlides
                ? { delay: 4000, disableOnInteraction: true, pauseOnMouseEnter: true }
                : false;
            var progressIndicator = document.querySelector('.progress-indicator-feature');
            var swiper = new Swiper('.post-swiper', {
                modules: [Navigation, Pagination, Autoplay],
                slidesPerView: 2.2,
                spaceBetween: 20,
                slidesOffsetAfter: 40,
                loop: hasMultipleSlides,
                autoplay: autoplay,
                navigation: {
                    nextEl: '.custom-button-next',
                    prevEl: '.custom-button-prev',
                },
                on: {
                    init: function (swiperInstance) {
                        if (swiperInstance.slides.length > 1 && progressIndicator) {
                            progressIndicator.style.width = '100%';
                        }
                    },
                    autoplayTimeLeft: function (swiperInstance, time, progress) {
                        if (swiperInstance.slides.length > 1 && progressIndicator) {
                            var reversedProgress = (1 - progress) * 100;
                            progressIndicator.style.width = "".concat(reversedProgress, "%");
                        }
                    },
                    slideChangeTransitionStart: function (swiperInstance) {
                        if (swiperInstance.slides.length > 1 && progressIndicator) {
                            progressIndicator.style.width = '0%';
                        }
                    },
                },
            });
            // Reset slide to position 0 when terms or paging changes
            this.$watch('terms', function () {
                swiper.setProgress(0);
            });
            this.$watch('currentPage', function () {
                swiper.setProgress(0);
            });
        },
    },
    mounted: function () {
        this.delayedBindSwiper();
    },
}));
