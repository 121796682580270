"use strict";
document.addEventListener('DOMContentLoaded', function () {
    var form = document.querySelector('.form-contact');
    if (!form) {
        console.warn("Form with class 'form-contact' not found in the DOM.");
        return; // Exit if the form is not found
    }
    var firstNameInput = document.getElementById('first-name');
    var lastNameInput = document.getElementById('last-name');
    var phoneInput = document.getElementById('phone-number');
    var emailInput = document.getElementById('email');
    var countrySelect = document.getElementById('country');
    var messageInput = document.getElementById('message');
    form.addEventListener('submit', function (event) {
        event.preventDefault();
        var isValid = validateForm();
        if (isValid) {
            alert('Form submitted successfully!');
            // Here you can handle form submission (e.g., sending data to the server)
        }
    });
    function validateForm() {
        var isValid = true;
        // Reset previous error messages
        resetErrors();
        // Validate First Name
        if (!firstNameInput.value.trim()) {
            showError(firstNameInput, 'First Name is required.');
            isValid = false;
        }
        // Validate Last Name
        if (!lastNameInput.value.trim()) {
            showError(lastNameInput, 'Last Name is required.');
            isValid = false;
        }
        // Validate Phone Number (optional, but if filled, should contain only numbers)
        if (phoneInput.value && !/^\d+$/.test(phoneInput.value)) {
            showError(phoneInput, 'Phone Number should contain only digits.');
            isValid = false;
        }
        // Validate Email
        if (emailInput.value && !validateEmail(emailInput.value)) {
            showError(emailInput, 'Please enter a valid email address.');
            isValid = false;
        }
        // Validate Country selection
        if (!countrySelect.value) {
            showError(countrySelect, 'Please select your country.');
            isValid = false;
        }
        // Validate Message (optional, but if filled, should be within reasonable length)
        if (messageInput.value && messageInput.value.length > 500) {
            showError(messageInput, 'Message should be less than 500 characters.');
            isValid = false;
        }
        return isValid;
    }
    function showError(element, message) {
        var _a;
        var errorDiv = document.createElement('div');
        errorDiv.className = 'text-red-600 text-sm mt-1';
        errorDiv.textContent = message;
        element.classList.add('border-red-500');
        (_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(errorDiv);
    }
    function resetErrors() {
        // Remove all error messages and reset styles
        var errorMessages = form.querySelectorAll('.text-red-600');
        errorMessages.forEach(function (msg) { return msg.remove(); });
        var errorFields = form.querySelectorAll('.border-red-500');
        errorFields.forEach(function (field) { return field.classList.remove('border-red-500'); });
    }
    function validateEmail(email) {
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }
});
