import Vue, { defineComponent } from 'vue';
export default Vue.component('template-post-grid', defineComponent({
    data: function () {
        return {
            selectedPost: null,
            isDrawerOpen: false,
            selectedTerms: [],
        };
    },
    methods: {
        onGridClick: function (data) {
            this.selectedPost = data;
            this.isDrawerOpen = true;
        },
        onDrawerClose: function () {
            this.isDrawerOpen = false;
            this.selectedPost = null;
        },
        onSelectTerms: function (terms) {
            this.selectedTerms = terms;
        },
    },
}));
