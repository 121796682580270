export var blockComparisonModule = function () {
    // Select all columns and their respective logos
    var columns = document.querySelectorAll("#block-comparison-module .company-column");
    if (columns.length === 0) {
        console.warn("No columns found with selector '#block-comparison-module .company-column'");
        return;
    }
    // Initially add 'active-border' class to the first column (index 0)
    columns[0].classList.add("active-border");
    // Function to handle the border change on logo click
    function handleBorderChange(event) {
        // Remove 'active-border' class from all columns
        columns.forEach(function (column) { return column.classList.remove("active-border"); });
        // Get the clicked logo and its associated column number
        var clickedLogo = event.currentTarget;
        var columnClassMatch = Array.from(clickedLogo.classList).find(function (className) {
            return className.startsWith("company-logo-");
        });
        if (columnClassMatch) {
            var columnNumber = columnClassMatch.replace("company-logo-", "");
            var targetColumn = document.querySelector(".company-column-".concat(columnNumber));
            if (targetColumn) {
                targetColumn.classList.add("active-border");
            }
        }
    }
    // Attach click event listener to each company logo
    // columns.forEach(column => {
    //   const logo = column.querySelector(".company-logo");
    //   if (logo) {
    //     logo.addEventListener("click", handleBorderChange);
    //   } else {
    //     console.warn("No logo found in column:", column);
    //   }
    // });
};
