import Swiper from 'swiper';
import 'swiper/css';
export var blockScrollingLogoCarousel = function () {
    new Swiper('.companies-slider', {
        slidesPerView: "auto",
        spaceBetween: 31,
        loop: true,
        autoplay: true,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        // Responsive breakpoints
        // breakpoints: {
        //     // Mobile view (<= 768px)
        //     320: {
        //         slidesPerView: 3,  // 3 slides per view on mobile
        //         spaceBetween: 30,  // Adjusted space between slides for mobile
        //     },
        //     // Larger than mobile (>= 769px)
        //     768: {
        //         slidesPerView: 7,  // 7 slides per view on larger screens
        //         spaceBetween: 50,  // Default space between slides
        //     }
        // }
    });
};
// init Swiper:
