import videojs from 'video.js';
import 'videojs-youtube/dist/Youtube.js';
import './videojs-vimeo';
var $ = jQuery;
window.Multimedia = {
    init: function () {
        var multimediasEls = $('.multimedia-video');
        multimediasEls.each(function (i, multimediaEl) {
            var videoEl = $('.video-js', multimediaEl);
            var videoContainer = $('.video-container', multimediaEl);
            // just make sure not loaded this multiple times
            if (!videoEl.data('playerInit')) {
                // setup the player
                var player_1 = videojs(videoEl[0], { controls: false });
                videoEl.data('player', player_1);
                player_1.ready(function () {
                    videoContainer.find('.video-js').removeClass('hidden');
                });
                // play/pause handler
                var playPauseEl = $('.play-pause-button', multimediaEl);
                playPauseEl.on('click', function () {
                    if (player_1.paused()) {
                        player_1.play();
                    }
                    else {
                        player_1.pause();
                    }
                });
                videoEl.data('playerInit', true);
            }
        });
    },
};
window.MultimediaPopUp = {
    init: function () {
        var multimediasEls = $('.multimedia-video');
        multimediasEls.each(function (i, multimediaEl) {
            var videoEl = $('.video-js', multimediaEl);
            // open video Pop up
            var videoPopup = $('.video-popup', multimediaEl);
            var videoCloseBtn = $('.close-popup', multimediaEl);
            var popupOpenBtn = $('.popup-open, .video-thumbnail', multimediaEl);
            popupOpenBtn.on('click', function () {
                videoPopup.removeClass('hidden');
                // implementation in multimedia.ts, put in here so that it will be dynamic loaded
                // because the loading will be after this code
                var player = videojs(videoEl[0], { controls: false });
                videoEl.data('player', player);
                player.play();
            });
            videoCloseBtn.on('click', function () {
                videoPopup.addClass('hidden');
                // implementation in multimedia.ts, put in here so that it will be dynamic loaded
                // because the loading will be after this code
                var player = videojs(videoEl[0], { controls: false });
                videoEl.data('player', player);
                player.pause();
            });
        });
    },
};
jQuery(function () {
    window.Multimedia.init();
    window.MultimediaPopUp.init();
});
