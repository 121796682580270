import Vue from 'vue';
var createAccordionTemplate = function () {
    return "<div class=\"accordion\">\n    <slot></slot>\n  </div>";
};
export default Vue.component('accordion', {
    template: createAccordionTemplate(),
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    mounted: function () {
        this.bindTriggerEvent();
    },
    methods: {
        bindTriggerEvent: function () {
            var _this = this;
            var accordionItems = this.$el.querySelectorAll('[data-accordion-item]');
            accordionItems.forEach(function (item) {
                var isActive = false;
                var trigger = item.querySelector('[data-accordion-trigger]');
                var content = item.querySelector('[data-accordion-content]');
                trigger === null || trigger === void 0 ? void 0 : trigger.addEventListener('click', function () {
                    var _a, _b;
                    if (!isActive) {
                        if (!_this.multiple) {
                            accordionItems.forEach(function (otherItem) {
                                var _a;
                                if (otherItem !== item) {
                                    var otherContent = otherItem.querySelector('[data-accordion-content]');
                                    var otherTrigger = otherItem.querySelector('[data-accordion-trigger]');
                                    otherContent === null || otherContent === void 0 ? void 0 : otherContent.classList.add('max-h-0');
                                    otherContent === null || otherContent === void 0 ? void 0 : otherContent.classList.remove('max-h-[500px]');
                                    otherContent === null || otherContent === void 0 ? void 0 : otherContent.classList.remove('py-[20px]');
                                    (_a = otherTrigger === null || otherTrigger === void 0 ? void 0 : otherTrigger.querySelector('.accordion-trigger-icon')) === null || _a === void 0 ? void 0 : _a.classList.remove('rotate-180');
                                }
                            });
                        }
                        content === null || content === void 0 ? void 0 : content.classList.add('py-[20px]');
                        content === null || content === void 0 ? void 0 : content.classList.remove('max-h-0');
                        content === null || content === void 0 ? void 0 : content.classList.add('max-h-[500px]');
                        (_a = trigger === null || trigger === void 0 ? void 0 : trigger.querySelector('.accordion-trigger-icon')) === null || _a === void 0 ? void 0 : _a.classList.add('rotate-180');
                    }
                    else {
                        content === null || content === void 0 ? void 0 : content.classList.add('max-h-0');
                        content === null || content === void 0 ? void 0 : content.classList.remove('max-h-[500px]');
                        content === null || content === void 0 ? void 0 : content.classList.remove('py-[20px]');
                        (_b = trigger === null || trigger === void 0 ? void 0 : trigger.querySelector('.accordion-trigger-icon')) === null || _b === void 0 ? void 0 : _b.classList.remove('rotate-180');
                    }
                    isActive = !isActive;
                });
            });
        }
    }
});
