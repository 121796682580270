import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
export var blockTestimonialCarousel = function () {
    var nextButton = document.querySelector(".block-testimonial-carousel-slider-button-next");
    var prevButton = document.querySelector(".block-testimonial-carousel-slider-button-prev");
    var swiper = new Swiper(".block-testimonial-carousel-slider", {
        modules: [Navigation, Pagination],
        slidesPerView: 1.2,
        autoplay: false,
        loop: false,
        freeMode: true,
        spaceBetween: 16,
        slidesOffsetBefore: 80,
        navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
        },
        breakpoints: {
            390: {
                slidesPerView: 1.2,
                spaceBetween: 0,
                slidesOffsetBefore: 16,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 0,
                slidesOffsetBefore: 16,
            },
            768: {
                slidesPerView: 3.45,
                spaceBetween: 0,
                slidesOffsetBefore: 30,
            },
            1025: {
                slidesPerView: 4,
                spaceBetween: 0,
                slidesOffsetBefore: -48,
            },
            1280: {
                slidesPerView: 4.2,
                spaceBetween: 0,
                slidesOffsetBefore: -100,
            },
        },
        on: {
            init: function () {
                // Initially hide the prev button with `invisible` class
                if (prevButton) {
                    prevButton.classList.add("invisible");
                }
            },
            slideChange: function () {
                if (prevButton) {
                    // Toggle visibility based on the current slide position
                    if (swiper.activeIndex > 0) {
                        prevButton.classList.remove("invisible");
                    }
                    else {
                        prevButton.classList.add("invisible");
                    }
                }
            },
        },
    });
};
