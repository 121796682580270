export var showFormBookNow = function () {
    var buttonBookNow = document.querySelectorAll('.book-now');
    buttonBookNow.forEach(function (item) {
        item.addEventListener('click', function () {
            var formBooking = document.getElementById('form-booking-event');
            var isHidden = formBooking === null || formBooking === void 0 ? void 0 : formBooking.classList.contains('hidden');
            if (isHidden) {
                item.style.display = 'none';
                formBooking === null || formBooking === void 0 ? void 0 : formBooking.classList.remove('hidden');
            }
            else {
                formBooking === null || formBooking === void 0 ? void 0 : formBooking.classList.add('hidden');
            }
        });
    });
};
