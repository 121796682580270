var activeFilters = {
    topic: new Set(),
    region: new Set(),
};
export var showFilterDropdown = function () {
    var accordionItems = document.querySelectorAll('.accordion-item-blog-sidebar');
    accordionItems.forEach(function (item) {
        var header = item.querySelector('.accordion-header-blog-sidebar');
        var content = item.querySelector('.accordion-content-blog-sidebar');
        header.addEventListener('click', function () {
            var isOpen = item.classList.contains('active');
            // Close all accordion items
            accordionItems.forEach(function (otherItem) {
                otherItem.classList.remove('active');
                var otherContent = otherItem.querySelector('.accordion-content-blog-sidebar');
                otherContent.style.height = '0';
                otherContent.style.paddingTop = '0';
                otherContent.style.paddingBottom = '0';
            });
            // Open the clicked item if it was closed
            if (!isOpen) {
                item.classList.add('active');
                content.style.height = "".concat(content.scrollHeight, "px"); // Dynamically set height
                content.style.paddingTop = '10px';
                content.style.paddingBottom = '10px';
            }
        });
    });
};
export var toggleDropdownBlog = function () {
    var filterDropdowns = document.querySelectorAll(".filter-dropdown");
    filterDropdowns.forEach(function (dropdown) {
        var label = dropdown.querySelector(".region-dropdown-label, .topic-dropdown-label, .author-dropdown-label");
        var content = dropdown.querySelector(".dropdown-content");
        label.addEventListener("click", function () {
            // Close all other dropdowns before opening the clicked one
            filterDropdowns.forEach(function (otherDropdown) {
                var otherContent = otherDropdown.querySelector(".dropdown-content");
                if (otherContent !== content) {
                    otherContent.classList.add("hidden");
                }
            });
            // Toggle the clicked dropdown content
            content.classList.toggle("hidden");
        });
    });
    var updateQueryParams = function () {
        var selectedRegions = Array.from(document.querySelectorAll(".region-filter:checked")).map(function (el) { return el.value; });
        var selectedTopics = Array.from(document.querySelectorAll(".topic-filter:checked")).map(function (el) { return el.value; });
        var selectedAuthors = Array.from(document.querySelectorAll(".author-filter:checked")).map(function (el) { return el.value; });
        var params = new URLSearchParams(window.location.search);
        if (selectedRegions.length > 0) {
            params.set("region", selectedRegions.join(","));
        }
        else {
            params.delete("region");
        }
        if (selectedTopics.length > 0) {
            params.set("topic", selectedTopics.join(","));
        }
        else {
            params.delete("topic");
        }
        if (selectedAuthors.length > 0) {
            params.set("author", selectedAuthors.join(","));
        }
        else {
            params.delete("author");
        }
        var newUrl = "".concat(window.location.pathname, "?").concat(params.toString());
        window.history.replaceState({}, "", newUrl);
    };
    var applyFilters = function () {
        var itemsDisplayed = 0;
        var featureItems = Array.from(document.querySelectorAll('.post-item'));
        featureItems.forEach(function (item) {
            var matchesFilter = Object.entries(activeFilters).every(function (_a) {
                var taxonomy = _a[0], values = _a[1];
                return values.size === 0 || Array.from(values).some(function (value) { var _a; return (_a = item.getAttribute("data-".concat(taxonomy))) === null || _a === void 0 ? void 0 : _a.split(',').includes(value); });
            });
            if (matchesFilter)
                itemsDisplayed++;
            item.style.display = matchesFilter ? 'block' : 'none';
        });
    };
    document.querySelectorAll(".region-filter, .topic-filter, .author-filter").forEach(function (checkbox) {
        checkbox.addEventListener("change", function () {
            var filterType = checkbox.getAttribute('data-filter-type') || '';
            var checkboxElement = checkbox;
            if (checkboxElement.checked) {
                activeFilters[filterType].add(checkboxElement.value);
            }
            else {
                activeFilters[filterType].delete(checkboxElement.value);
            }
            updateQueryParams();
            applyFilters();
        });
    });
};
export var setupBlogPagination = function () {
    // Check if we are on the feature page by looking for a unique feature page element
    var featureListElement = document.getElementById('blog-content');
    if (!featureListElement)
        return; // Exit if we're not on the feature page
    var itemsPerPage = 16; // Number of items per page
    var featureItems = Array.from(document.querySelectorAll('.post-item'));
    var pageNumbersContainer = document.getElementById('page-numbers');
    var prevButton = document.getElementById('prev-page-feature');
    var nextButton = document.getElementById('next-page-feature');
    var currentPage = 1;
    var totalPages = Math.ceil(featureItems.length / itemsPerPage);
    var isInitialLoad = true; // Track if this is the first load
    // Function to initialize currentPage from query parameters
    function initializePageFromUrl() {
        var params = new URLSearchParams(window.location.search);
        var pageParam = params.get('page');
        if (pageParam) {
            currentPage = Math.max(1, Math.min(totalPages, parseInt(pageParam, 10)));
        }
    }
    // Function to update the URL with the current page while preserving other query parameters
    function updateUrlWithPage() {
        if (isInitialLoad) {
            isInitialLoad = false; // Skip updating URL on the first load
            return;
        }
        var params = new URLSearchParams(window.location.search); // Use current URL parameters
        params.set('page', currentPage.toString()); // Update the page parameter
        history.replaceState(null, '', "".concat(window.location.pathname, "?").concat(params.toString()));
    }
    // Function to show items based on the current page
    function showPage(page) {
        currentPage = page;
        var startIndex = (page - 1) * itemsPerPage;
        var endIndex = startIndex + itemsPerPage;
        // Hide all items
        featureItems.forEach(function (item, index) {
            item.style.display = index >= startIndex && index < endIndex ? 'block' : 'none';
        });
        // Update pagination controls and URL
        updatePaginationControls();
        updateUrlWithPage();
    }
    // Function to update pagination controls
    function updatePaginationControls() {
        if (pageNumbersContainer) {
            pageNumbersContainer.innerHTML = ''; // Clear existing page numbers
            var _loop_1 = function (i) {
                var pageButton = document.createElement('button');
                pageButton.classList.add('w-[40px]', 'h-[40px]', 'rounded-[8px]', 'border', 'border-[#0000000D]', 'open-sans-600', 'text-[16px]');
                // Set the active or inactive background color
                if (i === currentPage) {
                    pageButton.classList.add('bg-[#65BB38]');
                }
                else {
                    pageButton.classList.add('bg-transparent');
                }
                pageButton.innerText = i.toString();
                pageButton.addEventListener('click', function () {
                    showPage(i);
                });
                pageNumbersContainer.appendChild(pageButton);
            };
            for (var i = 1; i <= totalPages; i++) {
                _loop_1(i);
            }
        }
        // Enable/disable prev and next buttons by hiding them
        if (prevButton) {
            prevButton.style.visibility = currentPage === 1 ? 'hidden' : 'visible';
        }
        if (nextButton) {
            nextButton.style.visibility = currentPage === totalPages ? 'hidden' : 'visible';
        }
    }
    // Event listeners for prev and next buttons
    if (prevButton) {
        prevButton.addEventListener('click', function () {
            if (currentPage > 1) {
                showPage(currentPage - 1);
            }
        });
    }
    if (nextButton) {
        nextButton.addEventListener('click', function () {
            if (currentPage < totalPages) {
                showPage(currentPage + 1);
            }
        });
    }
    // Initialize pagination by setting page from URL or default to first page
    initializePageFromUrl();
    showPage(currentPage);
};
