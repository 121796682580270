import math from '@/helpers/math';
import Vue from 'vue';
Vue.component('range-slider', {
    props: {
        initialValue: {
            type: Number,
            default: 0,
        },
        initialMax: {
            type: Number,
            default: 0,
        },
    },
    data: function () {
        return {
            value: this.initialValue || 0,
            percentage: 0,
            max: this.initialMax || 0,
            min: 0,
        };
    },
    methods: {
        onChange: function (value) {
            this.$emit('change', value);
            this.updateTotalProgress();
        },
        updateTotalProgress: function () {
            var value = Number(this.value || 0);
            var max = Number(this.max || 0);
            var min = Number(this.min || 0);
            var percentage = math.percent(value - min, max - min);
            this.percentage = percentage;
        },
    },
    watch: {
        initialValue: function (value) {
            this.value = value;
            this.onChange(value);
        },
        value: function (value) {
            this.onChange(value);
        },
    },
    mounted: function () {
        this.updateTotalProgress();
    },
});
