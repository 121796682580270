import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// ================================ ATTENTION =================================
// TODO: This is a temporary solution to the problem.
// TODO: Refactor soon
// ================================ ATTENTION =================================
export var blockScreenshotCarousel = function () {
    var progressIndicator = document.querySelector('.progress-indicator-screenshot');
    new Swiper('.block-screenshot-carousel-slider', {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 3.7,
        slidesOffsetBefore: 70,
        spaceBetween: 30,
        loop: true,
        autoplay: false,
        // Disabled autoplay for now
        // autoplay: {
        //     delay: 3000, // 3 seconds delay between slides
        //     disableOnInteraction: false,
        //     pauseOnMouseEnter: true,
        // },
        // pagination: {
        //     el: ".swiper-pagination-screenshot",
        //     type: "progressbar",
        // },
        navigation: {
            nextEl: ".custom-button-next",
            prevEl: ".custom-button-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1.2,
                spaceBetween: 10,
                slidesOffsetBefore: 20,
            },
            640: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesOffsetBefore: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
                slidesOffsetBefore: 20,
            },
            1024: {
                slidesPerView: 3.6,
                spaceBetween: 30,
                slidesOffsetBefore: 70,
            },
        },
        on: {
            init: function (swiperInstance) {
                // Ensure swiperInstance is ready before attaching events
                if (swiperInstance.slides.length > 1 && progressIndicator) {
                    progressIndicator.style.width = '100%'; // Initial state for multi-slide carousel
                }
            },
            // This event runs continuously and updates the progress bar width and time left
            autoplayTimeLeft: function (swiperInstance, time, progress) {
                if (swiperInstance.slides.length > 1 && progressIndicator) { // Only update if there are multiple slides
                    var reversedProgress = (1 - progress) * 100; // Calculate reversed width percentage
                    progressIndicator.style.width = "".concat(reversedProgress, "%"); // Set progress bar width based on reversed progress
                }
            },
            slideChangeTransitionStart: function (swiperInstance) {
                if (swiperInstance.slides.length > 1 && progressIndicator) { // Only reset if there are multiple slides
                    progressIndicator.style.width = '0%'; // Reset progress bar width at start of transition
                }
            }
        }
    });
};
