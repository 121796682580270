"use strict";
/* global geotWP */
var regionModal = document.querySelector('.region-modal');
var regionSwitchBanner = document.getElementById('region-switch-banner');
var switchButton = document.getElementById('switch-region');
// Function to show or hide the region modal
function showRegionModal(open) {
    if (open === void 0) { open = true; }
    if (!regionModal)
        return;
    regionModal.classList.toggle('active', open);
}
// Function to create a cookie
function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
// Function to read a cookie
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var _i = 0, ca_1 = ca; _i < ca_1.length; _i++) {
        var c = ca_1[_i];
        c = c.trim();
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length);
    }
    return null;
}
// Highlight the selected region based on cookie
function highlightSelectedRegion() {
    var selectedRegion = readCookie('AGRIWEBB_REGION');
    if (selectedRegion) {
        var selectedElement = document.querySelector(".region-modal-selector[data-iso=\"".concat(selectedRegion, "\"]"));
        if (selectedElement) {
            selectedElement.style.backgroundColor = "#f0f8ff"; // Apply your chosen background color here
        }
    }
}
// Get the region URLs from the data attribute on the switch banner
var regionUrlsString = (regionSwitchBanner === null || regionSwitchBanner === void 0 ? void 0 : regionSwitchBanner.dataset.regionUrls) || '';
var regionUrls = regionUrlsString.split(',').reduce(function (acc, item) {
    var _a = item.split(';'), iso = _a[0], url = _a[1];
    if (iso && url) {
        acc[iso] = url;
    }
    return acc;
}, {});
// Function to get the current region from the URL path
function getCurrentRegionFromUrl() {
    var pathRegion = window.location.pathname.split('/')[1].toUpperCase();
    return pathRegion || 'AU'; // Default to AU if no region is present in the path
}
// Function to detect user's location using IP and set the banner visibility accordingly
function detectUserLocation() {
    var cookieRegion = readCookie('AGRIWEBB_REGION');
    // If the cookie is already set, no need to detect location via IP
    if (cookieRegion) {
        console.log("Cookie for region already set:", cookieRegion);
        var currentRegion = getCurrentRegionFromUrl();
        // Show banner if the user's cookie region doesn't match the current region in the URL
        if (cookieRegion !== currentRegion) {
            try {
                var targetPathname = new URL(regionUrls[cookieRegion]).pathname;
                if (window.location.pathname !== targetPathname) {
                    regionSwitchBanner.style.display = 'block';
                    switchButton.dataset.url = regionUrls[cookieRegion]; // Set the URL for switching
                }
            }
            catch (e) {
                console.trace(e);
            }
        }
        return; // Skip IP detection if the cookie is defined
    }
    // IP detection if cookie is not set
    fetch("https://ipapi.co/json/")
        .then(function (response) {
        if (!response.ok) {
            throw new Error("API response not ok: ".concat(response.status));
        }
        return response.json();
    })
        .then(function (locationData) {
        var userCountryCode = (locationData.country_code || '').toUpperCase();
        // Only set the cookie if the user's detected region is recognized
        if (Object.keys(regionUrls).includes(userCountryCode)) {
            createCookie('AGRIWEBB_REGION', userCountryCode, 30);
            highlightSelectedRegion();
        }
        // Show banner if the user's detected region doesn't match the current region in the URL
        var currentRegion = getCurrentRegionFromUrl();
        if (userCountryCode !== currentRegion) {
            var targetPathname = new URL(regionUrls[userCountryCode]).pathname;
            if (window.location.pathname !== targetPathname) {
                regionSwitchBanner.style.display = 'block';
                switchButton.dataset.url = regionUrls[userCountryCode]; // Set the URL for switching
            }
        }
    })
        .catch(function (error) {
        console.error("Error detecting IP location or processing response:", error);
        regionSwitchBanner.style.display = 'none'; // Optionally hide the banner on error
    });
}
// Event listener for the switch region button
if (switchButton) {
    switchButton.addEventListener('click', function () {
        var url = switchButton.dataset.url;
        if (url) {
            window.location.href = url;
        }
    });
}
// Event listener for the close button on the region switch banner
var closeBannerButton = document.getElementById('close-banner');
if (closeBannerButton) {
    closeBannerButton.addEventListener('click', function () {
        regionSwitchBanner.style.display = 'none';
    });
}
document.addEventListener('DOMContentLoaded', regionSelector, false);
function regionSelector() {
    // Set up click event for region selectors in the modal
    var regionSelectors = document.querySelectorAll('.region-modal-selector');
    regionSelectors.forEach(function (region) {
        region.addEventListener('click', function (e) {
            e.preventDefault();
            var target = e.currentTarget;
            var iso = target.dataset.iso;
            var url = target.dataset.url;
            if (iso) {
                createCookie('AGRIWEBB_REGION', iso, 30); // Save cookie for 30 days
                if (url) {
                    window.location.href = url; // Redirect to the selected region's URL
                }
            }
        });
    });
}
// Toggle modal open/close
var toggleRegionModalButton = document.getElementById('toggle-region-modal');
if (toggleRegionModalButton) {
    toggleRegionModalButton.addEventListener('click', function (e) {
        e.preventDefault();
        showRegionModal();
    });
}
// Close modal on close button click
var regionModalCloseButton = document.getElementById('region-modal-close');
if (regionModalCloseButton) {
    regionModalCloseButton.addEventListener('click', function () {
        showRegionModal(false);
    });
}
// ================================
// TODO: enable later
// ================================
// Show the modal if the cookie does not exist
if (!readCookie('AGRIWEBB_REGION')) {
    // showRegionModal();
}
else {
    // highlightSelectedRegion();
}
// Run user location detection on page load
detectUserLocation();
