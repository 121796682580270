import $ from '@/lib/jquery';
import Vue, { defineComponent } from 'vue';
var Template = "\n<div\n  class=\"md:flex md:flex-col p-[28px] border border-[#E9E9E9] rounded-[20px]\"\n  :class=\"customClass\"\n>\n  <slot\n    :selected-terms=\"selectedTerms\"\n    :handle-select-all-checkbox-change=\"handleSelectAllCheckboxChange\"\n    :handle-select-terms=\"handleSelectTerms\"\n  ></slot>\n</div>\n";
Vue.component('post-filter-box', defineComponent({
    template: Template,
    props: {
        customClass: {
            type: String,
            default: '',
        },
    },
    data: function () {
        return {
            selectedTerms: [],
        };
    },
    watch: {
        selectedTerms: {
            handler: function (terms) {
                var selectedTerms = terms.map(function (term) {
                    var _a = term.split(':'), taxonomy = _a[0], slug = _a[1];
                    return {
                        taxonomy: taxonomy,
                        slug: slug,
                    };
                });
                this.$emit('select-terms', selectedTerms);
                this.updateUrlSearchParams();
            },
        },
    },
    methods: {
        handleSelectAllCheckboxChange: function (event) {
            var accordion = $('#selectTermsAccordion');
            // Toggle all accordion items
            var accordionItem = accordion === null || accordion === void 0 ? void 0 : accordion.find('[data-accordion-item]');
            if (accordionItem) {
                var checkbox = accordionItem.find('input[type="checkbox"]');
                var isChecked_1 = event.target.checked;
                checkbox.each(function (_, el) {
                    if (isChecked_1 && !$(el).is(':checked')) {
                        // Check unchecked boxes when "Select All" is checked
                        $(el).click();
                    }
                    else if (!isChecked_1 && $(el).is(':checked')) {
                        // Uncheck checked boxes when "Select All" is unchecked
                        $(el).click();
                    }
                });
            }
        },
        handleSelectTerms: function (term) {
            if (this.selectedTerms.includes(term)) {
                this.selectedTerms = this.selectedTerms.filter(function (t) { return t !== term; });
            }
            else {
                this.selectedTerms.push(term);
            }
        },
        selectTermsByUrlSearchParams: function () {
            var url = new URL(window.location.href);
            var params = new URLSearchParams(url.search);
            var queryTerms = params.get('terms');
            if (queryTerms) {
                this.selectedTerms = queryTerms.split('|');
            }
        },
        updateUrlSearchParams: function () {
            var url = new URL(window.location.href);
            var params = new URLSearchParams(url.search);
            params.set('terms', this.selectedTerms.join('|'));
            url.search = params.toString();
            window.history.replaceState({}, '', url.toString());
        },
    },
    mounted: function () {
        this.selectTermsByUrlSearchParams();
    },
}));
