var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var renderTemplateSlotAttributes = function (attributes, defaultAttributes) {
    if (defaultAttributes === void 0) { defaultAttributes = {}; }
    var mergedAttributes = __assign(__assign({}, defaultAttributes), attributes);
    return Object.keys(mergedAttributes).map(function (key) { return "".concat(key, "=\"").concat(mergedAttributes[key], "\""); }).join(' ');
};
export var createTemplate = function (renderTemplate, props, defaultProps) {
    if (props === void 0) { props = null; }
    if (defaultProps === void 0) { defaultProps = null; }
    var slotAttributes = renderTemplateSlotAttributes((props === null || props === void 0 ? void 0 : props.attributes) || {}, (defaultProps === null || defaultProps === void 0 ? void 0 : defaultProps.attributes) || {});
    return renderTemplate({ attributes: slotAttributes });
};
