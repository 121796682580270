var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue, { defineComponent } from 'vue';
import PostGrid, { createPostGridTemplate } from '@/modules/post/post-grid';
export default Vue.component('blogs-grid', 
// @ts-ignore
defineComponent({
    extends: PostGrid,
    template: createPostGridTemplate(),
    data: function () {
        return {
            perPage: 16,
        };
    },
    computed: {
        mappedPosts: function () {
            var _this = this;
            return this.filteredPosts.map(function (post) { return (__assign(__assign({}, post), { regionNames: _this.findPostTermNames(post, 'region').join(', ') })); });
        },
        posts: function () {
            var startIndex = (this.currentPage - 1) * this.perPage;
            var endIndex = startIndex + this.perPage;
            return this.mappedPosts.slice(startIndex, endIndex);
        },
        totalData: function () {
            return this.mappedPosts.length;
        },
    }
}));
