import Vue from 'vue';
Vue.component('button-toggle', {
    props: {
        initialValue: {
            type: String,
            default: '',
        },
    },
    data: function () {
        return {
            value: this.initialValue || '',
        };
    },
    methods: {
        setValue: function (value) {
            this.value = value;
            this.$emit('change', value);
        },
    },
    watch: {
        initialValue: function (value) {
            this.setValue(value);
        },
    },
});
