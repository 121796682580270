import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Vue from 'vue';
export default Vue.component('block-image-profile-carousel', {
    data: function () {
        return {
            selectedPost: null,
            isDrawerOpen: false,
        };
    },
    mounted: function () {
        this.initSwiper();
    },
    methods: {
        onGridClick: function (data) {
            this.selectedPost = data;
            this.isDrawerOpen = true;
        },
        onDrawerClose: function () {
            this.isDrawerOpen = false;
            this.selectedPost = null;
        },
        initSwiper: function () {
            new Swiper('.block-image-profile-carousel-slider', {
                // configure Swiper to use modules
                modules: [Navigation, Pagination, Autoplay],
                slidesPerView: 'auto',
                spaceBetween: 30,
                // Optional parameters
                autoplay: {
                    delay: 3000, // Adjust the delay for each slide transition in milliseconds
                    disableOnInteraction: false, // Keeps autoplay running even after user interaction
                },
                // Uncomment if pagination is needed
                // pagination: {
                //     el: '.swiper-pagination-custom',
                //     clickable: true,
                // },
                // Uncomment if navigation arrows are needed
                // navigation: {
                //     nextEl: '.testimonial-button-next',
                //     prevEl: '.testimonial-button-prev',
                // },
                // Loop the slides to create an infinite scrolling effect
                loop: true,
            });
        }
    }
});
