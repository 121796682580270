import { defineComponent } from 'vue';
var ComponentUtilsMixin = defineComponent({
    methods: {
        findChildren: function (name) {
            return this.$children.find(function (child) { return child.$options.name === name; });
        },
        isChildOf: function (name) {
            var _a, _b;
            return ((_b = (_a = this.$parent) === null || _a === void 0 ? void 0 : _a.$options) === null || _b === void 0 ? void 0 : _b.name) === name;
        },
        getParentComponent: function (name) {
            return this.isChildOf(name) ? this.$parent : null;
        },
        getParentData: function (name) {
            var _a;
            return (_a = this.getParentComponent(name)) === null || _a === void 0 ? void 0 : _a.$data;
        },
    },
});
export default ComponentUtilsMixin;
