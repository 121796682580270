var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// taken from https://github.com/fanhero/videojs-vimeo
/* eslint-disable */
// @ts-nocheck
import videojs from 'video.js';
import VimeoPlayer from '@vimeo/player';
var cssInjected = false;
// Since the iframe can't be touched using Vimeo's way of embedding,
// let's add a new styling rule to have the same style as `vjs-tech`
function injectCss() {
    if (cssInjected) {
        return;
    }
    cssInjected = true;
    var css = "\n    .vjs-vimeo iframe {\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n    }\n  ";
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    }
    else {
        style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
}
var Tech = videojs.getTech('Tech');
/**
 * Vimeo - Wrapper for Video Player API
 *
 * @param {Object=} options Object of option names and values
 * @param {Function=} ready Ready callback function
 * @extends Tech
 * @class Vimeo
 */
var Vimeo = /** @class */ (function (_super) {
    __extends(Vimeo, _super);
    function Vimeo(options, ready) {
        var _this = _super.call(this, options, ready) || this;
        injectCss();
        _this.setPoster(options.poster);
        _this.initVimeoPlayer();
        return _this;
    }
    Vimeo.prototype.initVimeoPlayer = function () {
        var _this = this;
        var vimeoOptions = {
            url: this.options_.source.src,
            byline: false,
            portrait: false,
            title: false
        };
        if (this.options_.autoplay) {
            vimeoOptions.autoplay = true;
        }
        if (this.options_.height) {
            vimeoOptions.height = this.options_.height;
        }
        if (this.options_.width) {
            vimeoOptions.width = this.options_.width;
        }
        if (this.options_.maxheight) {
            vimeoOptions.maxheight = this.options_.maxheight;
        }
        if (this.options_.maxwidth) {
            vimeoOptions.maxwidth = this.options_.maxwidth;
        }
        if (this.options_.loop) {
            vimeoOptions.loop = this.options_.loop;
        }
        if (this.options_.color) {
            vimeoOptions.color = this.options_.color.replace(/^#/, '');
        }
        this._player = new VimeoPlayer(this.el(), vimeoOptions);
        this.initVimeoState();
        ['play', 'pause', 'ended', 'timeupdate', 'progress', 'seeked'].forEach(function (e) {
            _this._player.on(e, function (progress) {
                if (_this._vimeoState.progress.duration !== progress.duration) {
                    _this.trigger('durationchange');
                }
                _this._vimeoState.progress = progress;
                _this.trigger(e);
            });
        });
        this._player.on('pause', function () { return (_this._vimeoState.playing = false); });
        this._player.on('play', function () {
            _this._vimeoState.playing = true;
            _this._vimeoState.ended = false;
        });
        this._player.on('ended', function () {
            _this._vimeoState.playing = false;
            _this._vimeoState.ended = true;
        });
        this._player.on('volumechange', function (v) { return (_this._vimeoState.volume = v); });
        this._player.on('error', function (e) { return _this.trigger('error', e); });
        this.triggerReady();
    };
    Vimeo.prototype.initVimeoState = function () {
        var state = this._vimeoState = {
            ended: false,
            playing: false,
            volume: 0,
            progress: {
                seconds: 0,
                percent: 0,
                duration: 0
            }
        };
        this._player.getCurrentTime().then(function (time) { return (state.progress.seconds = time); });
        this._player.getDuration().then(function (time) { return (state.progress.duration = time); });
        this._player.getPaused().then(function (paused) { return (state.playing = !paused); });
        this._player.getVolume().then(function (volume) { return (state.volume = volume); });
    };
    Vimeo.prototype.createEl = function () {
        var div = videojs.dom.createEl('div', {
            id: this.options_.techId
        });
        div.style.cssText = 'width:100%;height:100%;top:0;left:0;position:absolute';
        div.className = 'vjs-vimeo';
        return div;
    };
    Vimeo.prototype.controls = function () {
        return true;
    };
    Vimeo.prototype.supportsFullScreen = function () {
        return true;
    };
    Vimeo.prototype.src = function () {
        return this.options_.source;
    };
    Vimeo.prototype.currentSrc = function () {
        return this.options_.source.src;
    };
    Vimeo.prototype.currentTime = function () {
        return this._vimeoState.progress.seconds;
    };
    Vimeo.prototype.setCurrentTime = function (time) {
        this._player.setCurrentTime(time);
    };
    Vimeo.prototype.volume = function () {
        return this._vimeoState.volume;
    };
    Vimeo.prototype.setVolume = function (volume) {
        return this._player.setVolume(volume);
    };
    Vimeo.prototype.duration = function () {
        return this._vimeoState.progress.duration;
    };
    Vimeo.prototype.buffered = function () {
        var progress = this._vimeoState.progress;
        return videojs.createTimeRange(0, progress.percent * progress.duration);
    };
    Vimeo.prototype.paused = function () {
        return !this._vimeoState.playing;
    };
    Vimeo.prototype.pause = function () {
        this._player.pause();
    };
    Vimeo.prototype.play = function () {
        this._player.play();
    };
    Vimeo.prototype.muted = function () {
        return this._vimeoState.volume === 0;
    };
    Vimeo.prototype.ended = function () {
        return this._vimeoState.ended;
    };
    Vimeo.prototype.playbackRate = function () {
        return 1;
    };
    return Vimeo;
}(Tech));
Vimeo.prototype.featuresTimeupdateEvents = true;
Vimeo.isSupported = function () {
    return true;
};
// Add Source Handler pattern functions to this tech
Tech.withSourceHandlers(Vimeo);
Vimeo.nativeSourceHandler = {};
/**
 * Check if Vimeo can play the given videotype
 *
 * @param  {string} source    The mimetype to check
 * @return {string}         'maybe', or '' (empty string)
 */
Vimeo.nativeSourceHandler.canPlayType = function (source) {
    if (source === 'video/vimeo') {
        return 'maybe';
    }
    return '';
};
/*
 * Check Vimeo can handle the source natively
 *
 * @param  {Object} source  The source object
 * @return {String}         'maybe', or '' (empty string)
 * @note: Copied over from YouTube — not sure this is relevant
 */
Vimeo.nativeSourceHandler.canHandleSource = function (source) {
    if (source.type) {
        return Vimeo.nativeSourceHandler.canPlayType(source.type);
    }
    else if (source.src) {
        return Vimeo.nativeSourceHandler.canPlayType(source.src);
    }
    return '';
};
// @note: Copied over from YouTube — not sure this is relevant
Vimeo.nativeSourceHandler.handleSource = function (source, tech) {
    tech.src(source.src);
};
// @note: Copied over from YouTube — not sure this is relevant
Vimeo.nativeSourceHandler.dispose = function () { };
Vimeo.registerSourceHandler(Vimeo.nativeSourceHandler);
// Older versions of VJS5 doesn't have the registerTech function
if (typeof videojs.registerTech !== 'undefined') {
    videojs.registerTech('Vimeo', Vimeo);
}
else {
    videojs.registerComponent('Vimeo', Vimeo);
}
// Include the version number.
Vimeo.VERSION = '0.0.1';
export default Vimeo;
