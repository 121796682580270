export var blockTestimonial = function () {
    // DOM elements
    var prevBtn = document.getElementById("prev-btn");
    var nextBtn = document.getElementById("next-btn");
    var testimonialItems = document.querySelectorAll(".testimonial-item");
    var testimonialImages = document.querySelectorAll(".testimonial-item-image");
    var testimonialIndex = document.getElementById("testimonial-index");
    var currentIndex = 0;
    var totalSlides = testimonialItems.length;
    // Function to update testimonial visibility
    function updateTestimonial(index) {
        testimonialItems.forEach(function (item) {
            item.classList.remove("active");
            item.classList.add("hidden");
        });
        testimonialImages.forEach(function (image) {
            image.classList.remove("active");
            image.classList.add("hidden");
        });
        testimonialItems[index].classList.add("active");
        testimonialItems[index].classList.remove("hidden");
        testimonialImages[index].classList.add("active");
        testimonialImages[index].classList.remove("hidden");
        if (testimonialIndex) {
            testimonialIndex.textContent = "".concat(index + 1);
        }
    }
    // Event listeners for navigation if buttons exist
    nextBtn === null || nextBtn === void 0 ? void 0 : nextBtn.addEventListener("click", function () {
        currentIndex = (currentIndex + 1) % totalSlides;
        updateTestimonial(currentIndex);
    });
    prevBtn === null || prevBtn === void 0 ? void 0 : prevBtn.addEventListener("click", function () {
        currentIndex = (currentIndex - 1 + totalSlides) % totalSlides;
        updateTestimonial(currentIndex);
    });
    // Initialize with the first testimonial
    updateTestimonial(currentIndex);
};
