"use strict";
document.addEventListener('DOMContentLoaded', function () {
    var modal = document.getElementById("modal-custom");
    var closeModalButton = document.getElementById("closeModalButton");
    // Function to open modal with data from the clicked card
    function openModal(card) {
        if (!modal)
            return;
        console.log('card', card);
        // Access the data attributes
        var region = card.getAttribute('data-region') || '';
        var customer = card.getAttribute('data-customer') || '';
        var operationType = card.getAttribute('data-operation-type') || '';
        var content = card.getAttribute('data-content') || '';
        var imageSrc = card.getAttribute('data-image') || '';
        var goals = JSON.parse(card.getAttribute('data-goals') || '[]');
        // Populate modal elements, adding null checks
        var regionElement = modal.querySelector('.region');
        if (regionElement)
            regionElement.textContent = region;
        var customerElement = modal.querySelector('.customer-name');
        if (customerElement)
            customerElement.textContent = customer;
        var operationTypeElement = modal.querySelector('.operation-type');
        if (operationTypeElement)
            operationTypeElement.textContent = operationType;
        var contentElement = modal.querySelector('.content');
        if (contentElement)
            contentElement.textContent = content;
        var modalImage = modal.querySelector('.modal-image');
        if (modalImage)
            modalImage.src = imageSrc;
        // Populate goals section
        var goalsContainer = modal.querySelector('.goals-container');
        if (goalsContainer) {
            goalsContainer.innerHTML = ''; // Clear previous goals
            goals.forEach(function (goal) {
                var goalItem = document.createElement('div');
                goalItem.classList.add('goal-item', 'bg-[#F8F7F4]', 'rounded-[12px]', 'p-[10px]', 'flex', 'gap-[10px]');
                var icon = document.createElement('img');
                icon.src = goal.goals_icon.url;
                icon.alt = goal.goals_icon.alt;
                icon.classList.add('goal-icon', 'w-[53px]', 'h-[53px]', 'rounded-[12px]');
                var textContainer = document.createElement('div');
                var title = document.createElement('strong');
                title.textContent = goal.goals_text;
                var link = document.createElement('a');
                link.href = goal.goals_link.url;
                link.textContent = 'Learn more';
                link.target = '_blank';
                textContainer.appendChild(title);
                textContainer.appendChild(link);
                goalItem.appendChild(icon);
                goalItem.appendChild(textContainer);
                goalsContainer.appendChild(goalItem);
            });
        }
        // Display modal and lock background scroll
        modal.classList.remove("hidden");
        document.body.classList.add("no-scroll");
    }
    // Add click event listeners to all `.post-detail` elements
    document.querySelectorAll('.post-detail').forEach(function (card) {
        card.addEventListener('click', function (event) {
            event.preventDefault();
            openModal(card);
        });
    });
    // Close modal on button click
    closeModalButton === null || closeModalButton === void 0 ? void 0 : closeModalButton.addEventListener("click", function () {
        if (modal) {
            modal.classList.add("hidden");
            document.body.classList.remove("no-scroll");
        }
    });
    // Optional: Close modal when clicking outside of the content area
    modal === null || modal === void 0 ? void 0 : modal.addEventListener("click", function (event) {
        if (event.target === modal) {
            modal.classList.add("hidden");
            document.body.classList.remove("no-scroll");
        }
    });
});
