export function heeaderMenubarRight() {
    document.addEventListener('DOMContentLoaded', function () {
        var menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children > a');
        // Add click event listener to each menu item
        menuItemsWithChildren.forEach(function (menuItem) {
            menuItem.addEventListener('click', function (event) {
                var _this = this;
                event.preventDefault();
                var submenu = this.nextElementSibling;
                if (submenu) {
                    submenu.classList.toggle('visible');
                    this.classList.toggle('visible');
                }
                menuItemsWithChildren.forEach(function (item) {
                    var otherSubmenu = item.nextElementSibling;
                    if (item !== _this && otherSubmenu) {
                        otherSubmenu.classList.remove('visible');
                        item.classList.remove('visible');
                    }
                });
            });
        });
        // Close dropdown when clicking outside the menu
        document.addEventListener('click', function (event) {
            var isClickInsideMenu = event.target.closest('.menu-item-has-children');
            if (!isClickInsideMenu) {
                menuItemsWithChildren.forEach(function (menuItem) {
                    var submenu = menuItem.nextElementSibling;
                    submenu === null || submenu === void 0 ? void 0 : submenu.classList.remove('visible');
                    menuItem.classList.remove('visible');
                });
            }
        });
        // Handle menu and close buttons with null checks
        var menuButton = document.getElementById('btn-menu');
        var closeButton = document.getElementById('btn-close');
        var popupNavigation = document.getElementById('popup-navigation-right-desktop');
        if (menuButton && closeButton && popupNavigation) {
            menuButton.addEventListener('click', function () {
                popupNavigation.classList.remove('hidden');
                menuButton.classList.add('hidden');
                closeButton.classList.remove('hidden');
            });
            closeButton.addEventListener('click', function () {
                popupNavigation.classList.add('hidden');
                closeButton.classList.add('hidden');
                menuButton.classList.remove('hidden');
            });
        }
    });
}
export function checkHeaderWordpress() {
    document.addEventListener('DOMContentLoaded', function () {
        var header = document.getElementById('custom-header');
        var wpAdminBar = document.getElementById('wpadminbar');
        var menuMobile = document.getElementById('mobile-menu-toggle');
        if (header && wpAdminBar) {
            var adminBarHeight = wpAdminBar.offsetHeight;
        }
        if (menuMobile) {
            menuMobile.addEventListener('click', function () {
                var menu = document.getElementById('mobile-menu');
                if (menu) {
                    menu.classList.toggle('hidden');
                }
            });
        }
    });
}
