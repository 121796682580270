export var blockLeftAndRightContent = function () {
    var iconItems = document.querySelectorAll('.icon-item');
    // Function to collapse all items except the active one
    var collapseAllExcept = function (activeItem) {
        iconItems.forEach(function (item) {
            var description = item.querySelector('.description');
            var toggleIndicator = item.querySelector('.toggle-indicator svg');
            // Toggle description visibility
            if (description && item !== activeItem) {
                description.classList.add('hidden'); // Hide other descriptions
            }
            else if (description && item === activeItem) {
                description.classList.remove('hidden'); // Show the active item's description
            }
            // Toggle background color for active item
            if (item === activeItem) {
                item.classList.add('bg-active'); // Add background color to the active item
            }
            else {
                item.classList.remove('bg-active'); // Remove background color from inactive items
            }
            // Toggle indicator arrow direction
            if (toggleIndicator) {
                var isExpanded = description && !description.classList.contains('hidden');
                toggleIndicator.innerHTML = isExpanded
                    ? "<path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 15l7-7 7 7\"></path>" // Arrow up
                    : "<path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M19 9l-7 7-7-7\"></path>"; // Arrow down
            }
        });
    };
    // Initialize: Show the first description and collapse others
    var firstItem = iconItems[0];
    if (firstItem) {
        var firstDescription = firstItem.querySelector('.description');
        if (firstDescription) {
            firstDescription.classList.remove('hidden'); // Show first description
        }
        firstItem.classList.add('bg-active'); // Add background to the first item
        collapseAllExcept(firstItem); // Collapse all except the first one
    }
    // Add click event listener for toggling the description
    iconItems.forEach(function (item) {
        item.addEventListener('click', function (event) {
            var clickedItem = event.currentTarget;
            var description = clickedItem.querySelector('.description');
            if (description) {
                description.classList.toggle('hidden'); // Toggle the clicked description
            }
            collapseAllExcept(clickedItem); // Collapse all others except the clicked one
        });
    });
};
document.addEventListener('DOMContentLoaded', function () {
    var form = document.querySelector('.form-subscription');
    if (!form) {
        console.warn("Form with class 'form-subscription' not found in the DOM.");
        return; // Exit if the form is not found
    }
    var firstNameInput = form.querySelector('input[placeholder="First name"]');
    var emailInput = form.querySelector('input[placeholder="Email Address"]');
    form.addEventListener('submit', function (event) {
        event.preventDefault();
        var isValid = validateForm();
        if (isValid) {
            alert('Form submitted successfully!');
            // You can handle form submission here
        }
    });
    function validateForm() {
        var isValid = true;
        // Reset previous error messages
        resetErrors();
        // Validate First Name
        if (!firstNameInput.value.trim()) {
            showError(firstNameInput, 'First Name is required.');
            isValid = false;
        }
        // Validate Email Address
        if (!validateEmail(emailInput.value)) {
            showError(emailInput, 'Please enter a valid email address.');
            isValid = false;
        }
        return isValid;
    }
    function showError(element, message) {
        var _a;
        var errorDiv = document.createElement('div');
        errorDiv.className = 'text-red-600 text-sm mt-1';
        errorDiv.textContent = message;
        element.classList.add('border-red-500');
        (_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(errorDiv);
    }
    function resetErrors() {
        // Remove all error messages and reset styles
        var errorMessages = form.querySelectorAll('.text-red-600');
        errorMessages.forEach(function (msg) { return msg.remove(); });
        var errorFields = form.querySelectorAll('.border-red-500');
        errorFields.forEach(function (field) { return field.classList.remove('border-red-500'); });
    }
    function validateEmail(email) {
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }
});
