import Vue from 'vue';
import ScrollTo from '@/parts/scroll-to';
import $ from '@/lib/jquery';
import ComponentUtilsMixin from '@/helpers/mixins/component-utils-mixins';
Vue.component('template-pricing', {
    mixins: [ComponentUtilsMixin],
    data: function () { return ({
        period: 'yearly',
        totalCattle: 0,
        totalSheep: 0,
    }); },
    computed: {
        $calculator: function () {
            var _a;
            return $((_a = this.findChildren('pricing-plan-calculator')) === null || _a === void 0 ? void 0 : _a.$el);
        },
        $featuredCardFixed: function () {
            return this.$calculator.find('#feature-card-fixed');
        },
        $sectionCompare: function () {
            return $(this.$el).find('#section-compare');
        },
    },
    methods: {
        gotoCompare: function () {
            var _a;
            var height = this.$featuredCardFixed.height() || 0;
            var positionTop = this.$featuredCardFixed.position().top || 0;
            var offsetSticky = height + positionTop;
            var top = ((_a = this.$sectionCompare.offset()) === null || _a === void 0 ? void 0 : _a.top) || 0;
            ScrollTo.scrollTop(top - offsetSticky);
        },
    },
});
