import currency from 'currency.js';
// Main PriceCalculator class
var PriceCalculator = /** @class */ (function () {
    function PriceCalculator(priceJSON) {
        this.units = { cattle: 0, sheep: 0 };
        this.planPeriod = 'Monthly';
        this.priceJSON = priceJSON;
    }
    PriceCalculator.prototype.setUnits = function (unitObject) {
        this.units = unitObject;
    };
    PriceCalculator.prototype.getDSENumber = function () {
        return this.units.cattle * 8 + this.units.sheep * 1.5;
    };
    PriceCalculator.prototype.formatPrice = function (price) {
        return currency(price, { symbol: '', precision: 0 })
            .format()
            .replace(/\.0+$/, '');
    };
    PriceCalculator.prototype.getPrice = function (region, plan, planPeriod, dseThreshold, saleDiscountPercentage) {
        var _a, _b, _c;
        if (saleDiscountPercentage === void 0) { saleDiscountPercentage = 0; }
        var dseNumber = this.getDSENumber();
        // Check if the DSE number exceeds the threshold
        if (dseNumber >= dseThreshold) {
            return 'Talk to Us'; // Display "Talk to Us" instead of price
        }
        var priceStep = (_c = (_b = (_a = this.priceJSON[region]) === null || _a === void 0 ? void 0 : _a[plan]) === null || _b === void 0 ? void 0 : _b[planPeriod]) === null || _c === void 0 ? void 0 : _c.find(function (step) { return dseNumber <= step.unitCap; });
        if (!priceStep) {
            throw new Error('No price found for the given DSE number in the specified region, plan, and period.');
        }
        var price = priceStep.price;
        if (saleDiscountPercentage > 0) {
            price -= (price * saleDiscountPercentage) / 100;
        }
        price = price > 0 ? price / 100 : 0;
        return this.formatPrice(price);
    };
    return PriceCalculator;
}());
// Function to update pricing display based on plan period, cattle, and sheep values
function updatePricingDisplay(planPeriod, cattle, sheep) {
    var calculator = new PriceCalculator(globalData.pricing_data);
    calculator.setUnits({ cattle: cattle, sheep: sheep });
    var plans = document.querySelectorAll('.plan');
    plans.forEach(function (planElement) {
        var planName = planElement.getAttribute('data-plan-name') || '';
        var region = planElement.getAttribute('data-country-code') || '';
        var yearlyPriceEl = planElement.querySelector('.text-yearly-price');
        var yearlyPriceDiscountEl = planElement.querySelector('.text-yearly-price-discount');
        var monthlyPriceEl = planElement.querySelector('.text-monthly-price');
        var priceSuffixEl = planElement.querySelector('.price-suffix');
        var dseThreshold = parseInt(planElement.getAttribute('data-show-chat-text') || '40000');
        var discountPercentage = parseFloat(planElement.getAttribute('data-discount-percentage') || '0');
        var yearlyPrice = calculator.getPrice(region, planName, 'Yearly', dseThreshold, 0);
        var yearlyPriceDiscount = calculator.getPrice(region, planName, 'Yearly', dseThreshold, discountPercentage);
        var monthlyPrice = calculator.getPrice(region, planName, 'Monthly', dseThreshold, discountPercentage);
        if (planPeriod === 'Yearly') {
            if (yearlyPriceEl)
                yearlyPriceEl.textContent = yearlyPrice;
            if (yearlyPriceDiscountEl)
                yearlyPriceDiscountEl.textContent = yearlyPriceDiscount;
            if (priceSuffixEl)
                priceSuffixEl.textContent = '/year';
            if (monthlyPriceEl)
                monthlyPriceEl.textContent =
                    yearlyPrice === 'Talk to Us' ? '' : "or ".concat(monthlyPrice, " paid monthly");
        }
        else {
            if (yearlyPriceEl)
                yearlyPriceEl.textContent = monthlyPrice;
            if (priceSuffixEl)
                priceSuffixEl.textContent = '/month';
            if (monthlyPriceEl)
                monthlyPriceEl.textContent =
                    yearlyPrice === 'Talk to Us' ? '' : "or ".concat(yearlyPrice, " paid yearly");
        }
    });
}
function updateAddonsDisplay(cattle, sheep) {
    var dseNumber = cattle * 8 + sheep * 1.5;
    var parentCards = document.querySelectorAll('.parent-card');
    parentCards.forEach(function (parentCard) {
        var hasVisibleItems = false;
        // Check each add-on and integration section within the parent card
        var addonSections = parentCard.querySelectorAll('.add-on-section, .integration-section');
        addonSections.forEach(function (section) {
            var showThreshold = parseInt(section.getAttribute('data-show-addons') || '0');
            var sectionHasVisibleItems = false;
            var items = section.querySelectorAll('.add-on-item, .integration-item');
            items.forEach(function (item) {
                var itemThreshold = parseInt(item.getAttribute('data-show') || '0');
                // If itemThreshold is 0, it inherits the parent's showThreshold
                if (itemThreshold === 0) {
                    itemThreshold = showThreshold;
                }
                // Show or hide the item based on the DSE number
                if (dseNumber >= itemThreshold) {
                    item.classList.remove('hidden');
                    sectionHasVisibleItems = true;
                }
                else {
                    item.classList.add('hidden');
                }
            });
            // Show/hide the section based on visible items
            if (sectionHasVisibleItems) {
                section.classList.remove('hidden');
                hasVisibleItems = true;
            }
            else {
                section.classList.add('hidden');
            }
        });
        // Hide the parent card if no visible items in any section
        if (hasVisibleItems) {
            parentCard.classList.remove('hidden');
        }
        else {
            parentCard.classList.add('hidden');
        }
    });
}
// Function to initialize controls and attach event listeners
function initializeControls() {
    var enterManuallyBtn = document.getElementById('enterManuallyBtn');
    var goBackBtn = document.getElementById('goBackBtn');
    var rangeSliderContainers = document.querySelectorAll('.rangeSliderContainer');
    var manualInputContainer = document.getElementById('manualInputContainer');
    var toggleMonthlys = document.querySelectorAll('.toggleMonthly');
    var toggleYearlys = document.querySelectorAll('.toggleYearly');
    var cattleRanges = document.querySelectorAll('.cattleRange');
    var sheepRanges = document.querySelectorAll('.sheepRange');
    var cattleInput = document.getElementById('cattleInput');
    var sheepInput = document.getElementById('sheepInput');
    var cattleValues = document.querySelectorAll('.cattleValue');
    var sheepValues = document.querySelectorAll('.sheepValue');
    var planPeriod = 'Yearly';
    // Function to get the sum of current values from all cattle and sheep ranges
    function getCurrentSliderValues() {
        var cattleSum = 0;
        var sheepSum = 0;
        cattleRanges.forEach(function (cattleRange) {
            cattleSum += Number(cattleRange.value) || 0;
            console.log('cattleRange.value', cattleRange.value);
        });
        sheepRanges.forEach(function (sheepRange) {
            sheepSum += Number(sheepRange.value) || 0;
        });
        return { cattle: cattleSum, sheep: sheepSum };
    }
    // Function to update the toggle styles and pricing display with current values
    function updateToggleStyles(selectedPeriod) {
        planPeriod = selectedPeriod;
        toggleMonthlys.forEach(function (toggle) {
            toggle.classList.toggle('bg-[#39A7C4]', selectedPeriod === 'Monthly');
            toggle.classList.toggle('text-[#092343]', selectedPeriod === 'Monthly');
        });
        toggleYearlys.forEach(function (toggle) {
            toggle.classList.toggle('bg-[#39A7C4]', selectedPeriod === 'Yearly');
            toggle.classList.toggle('text-[#092343]', selectedPeriod === 'Yearly');
        });
        if (cattleInput && sheepInput) {
            cattleInput.value = '';
            sheepInput.value = '';
        }
        // Retrieve the current slider values
        var _a = getCurrentSliderValues(), cattle = _a.cattle, sheep = _a.sheep;
        // Update pricing display with the current slider values
        updatePricingDisplay(planPeriod, cattle, sheep);
        updateAddonsDisplay(cattle, sheep);
    }
    // Add event listeners for monthly/yearly toggles
    toggleMonthlys.forEach(function (toggle) {
        return toggle.addEventListener('click', function () { return updateToggleStyles('Monthly'); });
    });
    toggleYearlys.forEach(function (toggle) {
        return toggle.addEventListener('click', function () { return updateToggleStyles('Yearly'); });
    });
    // Event listener for cattle and sheep ranges to update the pricing in real-time
    cattleRanges.forEach(function (cattleRange, index) {
        cattleRange.addEventListener('input', function (a) {
            console.log('cattleRange.addEventListener', cattleRange.value);
            var _a = getCurrentSliderValues(), cattle = _a.cattle, sheep = _a.sheep;
            updatePricingDisplay(planPeriod, cattle, sheep);
            updateAddonsDisplay(cattle, sheep);
        });
    });
    sheepRanges.forEach(function (sheepRange, index) {
        sheepRange.addEventListener('input', function () {
            var _a = getCurrentSliderValues(), cattle = _a.cattle, sheep = _a.sheep;
            updatePricingDisplay(planPeriod, cattle, sheep);
            updateAddonsDisplay(cattle, sheep);
        });
    });
    // Event listener for "Enter Manually" button
    enterManuallyBtn === null || enterManuallyBtn === void 0 ? void 0 : enterManuallyBtn.addEventListener('click', function () {
        rangeSliderContainers.forEach(function (container) {
            container.style.display = 'none';
            // Reset each slider and its associated display position
            var rangeInput = container.querySelector("input[type='range']");
            var valueDisplay = container.querySelector('span');
            if (valueDisplay)
                valueDisplay.style.left = '0';
        });
        if (manualInputContainer)
            manualInputContainer.style.display = 'flex';
        if (enterManuallyBtn)
            enterManuallyBtn.style.display = 'none';
        if (goBackBtn)
            goBackBtn.style.display = 'flex';
        // Reset slider values and positions
        cattleRanges.forEach(function (range) {
            range.value = '0';
        });
        sheepRanges.forEach(function (range) {
            range.value = '0';
        });
        if (cattleInput && sheepInput) {
            cattleInput.value = '';
            sheepInput.value = '';
        }
        cattleInput === null || cattleInput === void 0 ? void 0 : cattleInput.addEventListener('input', enforceNumericInput);
        sheepInput === null || sheepInput === void 0 ? void 0 : sheepInput.addEventListener('input', enforceNumericInput);
        cattleInput === null || cattleInput === void 0 ? void 0 : cattleInput.addEventListener('focus', removeInitialZero);
        sheepInput === null || sheepInput === void 0 ? void 0 : sheepInput.addEventListener('focus', removeInitialZero);
        if (cattleInput && sheepInput) {
            cattleInput.addEventListener('input', function () {
                var cattle = Number(cattleInput.value) || 0;
                var sheep = Number(sheepInput.value) || 0;
                updatePricingDisplay(planPeriod, cattle, sheep);
                updateAddonsDisplay(cattle, sheep);
            });
            sheepInput.addEventListener('input', function () {
                var cattle = Number(cattleInput.value) || 0;
                var sheep = Number(sheepInput.value) || 0;
                updatePricingDisplay(planPeriod, cattle, sheep);
                updateAddonsDisplay(cattle, sheep);
            });
        }
    });
    // Event listener for "Go Back" button
    goBackBtn === null || goBackBtn === void 0 ? void 0 : goBackBtn.addEventListener('click', function () {
        rangeSliderContainers.forEach(function (container) {
            container.style.display = 'flex';
            // Reset each cattle and sheep value display position
            var cattleValueDisplay = container.querySelector('.cattleValue');
            var sheepValueDisplay = container.querySelector('.sheepValue');
            if (cattleValueDisplay)
                cattleValueDisplay.style.left = '0';
            if (sheepValueDisplay)
                sheepValueDisplay.style.left = '0';
        });
        if (manualInputContainer)
            manualInputContainer.style.display = 'none';
        if (enterManuallyBtn)
            enterManuallyBtn.style.display = 'flex';
        if (goBackBtn)
            goBackBtn.style.display = 'none';
        cattleRanges.forEach(function (range) {
            range.value = '0';
        });
        sheepRanges.forEach(function (range) {
            range.value = '0';
        });
        var _a = getCurrentSliderValues(), cattle = _a.cattle, sheep = _a.sheep;
        updatePricingDisplay(planPeriod, cattle, sheep);
        updateAddonsDisplay(cattle, sheep);
        cattleValues.forEach(function (value) { return (value.textContent = '0'); });
        sheepValues.forEach(function (value) { return (value.textContent = '0'); });
    });
    // Initial setup with current slider values
    var _a = getCurrentSliderValues(), cattle = _a.cattle, sheep = _a.sheep;
    updateToggleStyles(planPeriod);
    updatePricingDisplay(planPeriod, cattle, sheep);
    updateAddonsDisplay(cattle, sheep);
}
// Helper functions for input validation
function enforceNumericInput(event) {
    var target = event.target;
    target.value = target.value.replace(/\D/g, '');
}
function removeInitialZero(event) {
    var target = event.target;
    if (target.value === '0') {
        target.value = '';
    }
}
// Initialize controls and check display on first load
document.addEventListener('DOMContentLoaded', function () {
    // initializeControls()
    // updateAddonsDisplay(0, 0) // Check DSE-dependent display on initial load
});
