import videojs from 'video.js';
export var showPopupBlockImage = function () {
    var playVideoButton = document.querySelector('.play-video-button');
    var videoPopup = document.querySelector('.video-popup');
    var closePopupButton = videoPopup.querySelector('.close-popup');
    var playPauseButton = videoPopup.querySelector('.play-pause-button');
    var videoElement = videoPopup.querySelector('#popup-video-player');
    var videoSource = videoPopup.querySelector('#popup-video-source');
    // Initialize video.js player
    var player = videojs(videoElement, {
        controls: false,
        autoplay: false,
        muted: true, // Initially muted for compatibility
        loop: true, // Enable video loop
    });
    // Function to open the video popup
    function openVideoPopup(videoUrl) {
        if (videoUrl) {
            videoSource.src = videoUrl;
            player.load(); // Load the video source
            videoPopup.classList.remove('hidden');
            videoPopup.classList.add('flex');
            player.muted(false); // Unmute on autoplay
            player.play(); // Autoplay when popup opens
        }
    }
    // Function to close the video popup and reset the video
    function closeVideoPopup() {
        videoPopup.classList.add('hidden');
        videoPopup.classList.remove('flex');
        player.pause(); // Pause video when popup closes
        player.currentTime(0); // Reset video to the beginning
    }
    // Event listener for the "Watch the full video" button
    playVideoButton === null || playVideoButton === void 0 ? void 0 : playVideoButton.addEventListener('click', function () {
        var videoUrl = playVideoButton.getAttribute('data-video-url');
        if (videoUrl) {
            openVideoPopup(videoUrl);
        }
    });
    // Event listener for the close button
    closePopupButton === null || closePopupButton === void 0 ? void 0 : closePopupButton.addEventListener('click', closeVideoPopup);
    // Event listener for clicking outside the video container
    videoPopup.addEventListener('click', function (event) {
        if (event.target === videoPopup) {
            closeVideoPopup();
        }
    });
    // Event listener for the custom play/pause button
    playPauseButton === null || playPauseButton === void 0 ? void 0 : playPauseButton.addEventListener('click', function () {
        if (player.paused()) {
            player.play();
        }
        else {
            player.pause();
        }
    });
};
