import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export var blockStatisticComponent = function () {
    var progressIndicator = document.querySelector('.progress-indicator');
    var swiper = new Swiper('.slider-statistic-component', {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 300000000, // 3 seconds delay between slides
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: "#statistic-next-btn",
            prevEl: "#statistic-prev-btn",
        },
        on: {
            init: function (swiperInstance) {
                // Ensure swiperInstance is ready before attaching events
                if (swiperInstance.slides.length > 1 && progressIndicator) {
                    progressIndicator.style.width = '100%'; // Initial state for multi-slide carousel
                }
            },
            // This event runs continuously and updates the progress bar width and time left
            autoplayTimeLeft: function (swiperInstance, time, progress) {
                if (swiperInstance.slides.length > 1 && progressIndicator) { // Only update if there are multiple slides
                    var reversedProgress = (1 - progress) * 100; // Calculate reversed width percentage
                    progressIndicator.style.width = "".concat(reversedProgress, "%"); // Set progress bar width based on reversed progress
                }
            },
            slideChangeTransitionStart: function (swiperInstance) {
                if (swiperInstance.slides.length > 1 && progressIndicator) { // Only reset if there are multiple slides
                    progressIndicator.style.width = '0%'; // Reset progress bar width at start of transition
                }
            }
        }
    });
    // Trigger Swiper initialization
    swiper.init();
};
