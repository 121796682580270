import $ from '@/lib/jquery';
import $global from '@/helpers/global-elements';
var ScrollTo = {
    $element: function () { return $('button[data-scrollto]'); },
    target: function (element) { return $(element.data('scrollto')); },
    offsetTop: function () {
        var headerHeight = $global.$mainHeader().height() || 0;
        var wpAdminBarHeight = $global.$wpAdminBar().height() || 0;
        return headerHeight + wpAdminBarHeight;
    },
    scrollTop: function (scrollTop) {
        $('html, body').animate({
            scrollTop: scrollTop - this.offsetTop(),
        }, 800);
    },
    scrollTo: function ($element) {
        var _a;
        if ($element.length) {
            var top_1 = ((_a = $element.offset()) === null || _a === void 0 ? void 0 : _a.top) || 0;
            this.scrollTop(top_1);
        }
    },
    setup: function () {
        var _this = this;
        var _loop_1 = function (element) {
            var $element = $(element);
            $element.on('click', function () {
                _this.scrollTo(_this.target($element));
            });
        };
        for (var _i = 0, _a = this.$element().toArray(); _i < _a.length; _i++) {
            var element = _a[_i];
            _loop_1(element);
        }
    },
};
$(function () {
    ScrollTo.setup();
});
export default ScrollTo;
