export var blockPricingOption = function () {
    // Get the parent section by ID
    var pricingOptionSection = document.getElementById("block-pricing-option");
    if (pricingOptionSection) {
        // Find the inner block within the parent section
        var innerBlock = pricingOptionSection.querySelector(".inner-block");
        console.log('innerBlock', innerBlock);
        // Check if the inner block exists and has child elements
        if (innerBlock && innerBlock.children.length > 0) {
            // Add 'bg-dark' class to inner block if it has children
            innerBlock.classList.add("bg-dark");
        }
    }
};
